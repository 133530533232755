



















































































































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import useContact from "@/use/contact";
import useUser from "@/use/user";

export default defineComponent({
  components: {
    ALogo: () => import("@/components/atoms/a-logo.vue"),
    MCompanyModeDialog: () =>
      import("@/components/molecules/m-company-mode-dialog.vue"),
  },

  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const state = reactive({
      user: computed(() => root.$store.getters["user/getData"]),
    });

    const { getUnread, hasUnread } = useContact({ root });

    const logout = () => {
      root.$store.dispatch("user/logout").then(() => {
        root.$router.push({ name: "auth" });
      });
    };

    const isCompanyModeEnabled = computed(
      () => root.$store.getters["companyMode/isCompanyModeEnabled"]
    );

    return {
      state,
      logout,
      isCompanyModeEnabled,
      hasAccessTo,
      hasManyCompanies,
      getUnread,
      hasUnread,
    };
  },
});
